$(document).ready(function(){

	var hamburger = $('.js-mobile-menu-trigger'),
		body = $('body');

	hamburger.on('click', function(e){

		e.stopPropagation();

		var that = $(this);

		if( body.hasClass('mobile-menu-opened') ){
			body.removeClass('mobile-menu-opened');
			that.find('i').removeClass('fa-times').addClass('fa-bars')
		} else {
			body.addClass('mobile-menu-opened');
			that.find('i').removeClass('fa-bars').addClass('fa-times')
		}

	})

	$('.mobile-menu').find('.is-dropdown').each(function(){
		var that = $(this);

		that.on('click', function(){
			that.toggleClass('is-toggled');
		});
	})

	body.on('click', '.mobile-menu-container', function(e){
		if(!$(e.target).hasClass('mobile-menu-container')){
			return;
		}
		if( body.hasClass('mobile-menu-opened') ){
			body.removeClass('mobile-menu-opened');
			hamburger.find('i').removeClass('fa-times').addClass('fa-bars')
		} else {
			body.addClass('mobile-menu-opened');
			hamburger.find('i').removeClass('fa-bars').addClass('fa-times')
		}
	})
});
